import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";

const PolicyModalContent = () => {
  return (
    <Box>
    <Typography mt={2}>
      These Terms and Conditions (&quot;Agreement&quot;) govern your access to
      and use of the software- as-a-service (SaaS) product
      (&quot;Product&quot;) provided by Egora (&quot;Company&quot;). By
      accessing or using the Product, you agree to be bound by the terms and
      conditions set forth in this Agreement.
    </Typography>

    <Typography variant="h5" mt={2}>
      Account Registration and Usage
    </Typography>

    <Typography variant="h6" mt={2}>
      Account Registration:
    </Typography>
    <Typography>
      To utilise the Product, you must register an account by providing
      accurate and complete information. You are solely responsible for
      maintaining the confidentiality of your account credentials and for all
      actions taken under your account.
    </Typography>

    <Typography variant="h6" mt={2}>
      Authorised Users:
    </Typography>

    <Typography>
      You may authorise individuals (&quot;Authorized Users&quot;) to access
      and use the Product on your behalf. You are responsible for ensuring
      that Authorized Users adhere to the terms of this Agreement.
    </Typography>

    <Typography variant="h6" mt={2}>
      Usage Restrictions:
    </Typography>
    <Typography>
      You agree not to use the Product for any unlawful or unauthorised
      purposes. Specifically, you shall not, directly or indirectly, (a)
      reverse engineer, decompile, or disassemble the Product; (b) interfere
      with or disrupt the integrity or performance of the Product or its
      associated systems; (c) attempt to gain unauthorised access to the
      Product or its related systems; (d) use the Product to transmit any
      harmful or malicious code; or (e) sell, resell, sublicense, or
      distribute the Product to any third party.
    </Typography>

    <Typography variant="h5" mt={2}>
      Intellectual Property
    </Typography>

    <Typography variant="h6" mt={2}>
      Ownership:
    </Typography>

    <Typography>
      The Product, including all intellectual property rights therein, is the
      exclusive property of the Company or its licensors. This Agreement does
      not convey any rights or ownership in or to the Product, except for the
      limited license to use the Product as explicitly outlined herein.
    </Typography>

    <Typography variant="h6" mt={2}>
      Feedback:
    </Typography>
    <Typography>
      If you provide any suggestions, feedback, or ideas regarding the Product
      (&quot;Feedback&quot;), you grant the Company a non-exclusive,
      royalty-free, perpetual, irrevocable, and sublicensable right to use,
      modify, and incorporate such Feedback into the Product without any
      obligation to provide compensation to you.
    </Typography>

    <Typography variant="h5" mt={2}>
      Data Collection and Use:
    </Typography>

    <Typography variant="h6" mt={2}>
      Data Privacy and Security
    </Typography>

    <Typography>
      The Company may collect and utilise certain information and data related
      to your use of the Product, in accordance with the Company&#39;s Privacy
      Policy. By using the Product, you consent to such collection and use of
      your data.
    </Typography>

    <Typography variant="h6" mt={2}>
      Security Measures:
    </Typography>
    <Typography>
      The Company employs industry-standard security measures to safeguard the
      integrity and confidentiality of your data. However, the Company cannot
      guarantee that unauthorised third parties will not be able to bypass
      these measures. You acknowledge and agree that you use the Product at
      your own risk.
    </Typography>

    <Typography variant="h5" mt={2}>
      Payment and Subscription
    </Typography>

    <Typography variant="h6" mt={2}>
      Fees:
    </Typography>
    <Typography>
      Your access to and use of the Product may be subject to the payment of
      fees as outlined by the Company. You agree to promptly pay all
      applicable fees.
    </Typography>

    <Typography variant="h6" mt={2}>
      Subscription Term and Renewal:
    </Typography>
    <Typography>
      The Product is provided on a subscription basis for a specified term.
      Unless otherwise terminated, your subscription will automatically renew
      for additional terms upon expiration, and you will be billed
      accordingly.
    </Typography>

    <Typography variant="h6" mt={2}>
      Termination:
    </Typography>
    <Typography>
      The Product is provided on a subscription basis for a specified term.
      Unless otherwise terminated, your subscription will automatically renew
      for additional terms upon expiration, and you will be billed
      accordingly.
    </Typography>

    <Typography variant="h6" mt={2}>
      Subscription Term and Renewal:
    </Typography>
    <Typography>
      Either party may terminate this Agreement by providing written notice if
      the other party materially breaches any provision of this Agreement and
      fails to rectify the breach within 30 days of receiving written notice.
      Upon termination, your access to the Product will be revoked, and you
      will no longer be able to use the Product.
    </Typography>

    <Typography variant="h5" mt={2}>
      Limitation of Liability
    </Typography>

    <Typography>
      TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT SHALL
      THE COMPANY BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL,
      SPECIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUE, WHETHER
      INCURRED DIRECTLY OR INDIRECTLY, ARISING OUT OF OR RELATED TO YOUR USE
      OF THE PRODUCT, EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY
      OF SUCH DAMAGES..
    </Typography>

    <Typography variant="h5" mt={2}>
      General Provisions
    </Typography>

    <Typography variant="h6" mt={2}>
      Modification:
    </Typography>
    <Typography>
      The Company reserves the right to modify this Agreement from time to
      time. Any modifications will become effective upon the posting of the
      updated Agreement on the Company&#39;s website. Continued use of the
      Product after such modifications constitutes your acceptance of the
      revised Agreement.
    </Typography>

    <Typography variant="h6" mt={2}>
      Governing Law and Jurisdiction:
    </Typography>
    <Typography>
      This Agreement shall be governed by and construed in accordance with the
      laws of the Islamic Republic of Pakistan. Any disputes arising from or
      in connection with this Agreement shall be subject to the exclusive
      jurisdiction of the courts located in the Islamic Republic of Pakistan.
    </Typography>

    <Typography variant="h6" mt={2}>
      Entire Agreement:
    </Typography>
    <Typography>
      This Agreement constitutes the entire understanding between you and the
      Company concerning the subject matter hereof and supersedes all prior or
      contemporaneous agreements, communications, and understandings, whether
      oral or written.
    </Typography>

    <Typography variant="h6" mt={2} mb={2}>
      Please read this Agreement carefully before accessing or using the
      Product. By accessing or using the Product, you acknowledge that you
      have read, understood, and agree to be bound by the terms and conditions
      of this Agreement.
    </Typography>
  </Box>

  );
};

export default PolicyModalContent;
