import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";

const FaqModalContent = () => {
  return (
    <Box>
      <Typography variant="h6" mt={2}>
        1. What is Egora?
      </Typography>
      <Typography>
        Egora is a comprehensive platform designed to streamline operations for
        hospitality venues. We provide services including QR code ordering,
        table reservations, delivery/pick-up order management, and more to
        enhance the dining experience.
      </Typography>

      <Typography variant="h6" mt={2}>
        2. How does QR code ordering work?
      </Typography>
      <Typography>
        Customers can place orders on-site at participating venues by scanning
        QR codes provided at their tables. This allows for a seamless ordering
        experience, where customers can browse the menu, customize their orders,
        and pay directly through the platform.
      </Typography>

      <Typography variant="h6" mt={2}>
        3. Can I request a refund for my QR code order?
      </Typography>

      <Typography>
        Refunds for QR code orders are handled directly by the venue. If you
        encounter issues such as incorrect or defective orders, please notify
        the venue staff immediately. Refunds may be granted at the venue&#39;s
        discretion.
      </Typography>

      <Typography variant="h6" mt={2}>
        4. What should I do if my order is incorrect or defective?
      </Typography>
      <Typography>
        If your order is incorrect or defective, inform the venue staff
        immediately after receiving it. The venue will address the issue and
        determine whether a refund, replacement, or correction is appropriate.
      </Typography>

      <Typography variant="h6" mt={2}>
        5. How can I cancel a QR code order?
      </Typography>

      <Typography>
        QR code orders typically cannot be canceled once placed. However, if you
        need to cancel an order, please speak to the venue staff as soon as
        possible. Cancellations and refunds are subject to the venue’s policy.
      </Typography>

      <Typography variant="h6" mt={2}>
        6. What about delivery and pick-up orders?
      </Typography>
      <Typography>
        For delivery or pick-up orders, if there is a significant delay (more
        than 30 minutes) or if your order is incorrect, please contact the venue
        directly. Refunds for these orders will also be managed by the venue.
      </Typography>

      <Typography variant="h6" mt={2}>
        7. How do table reservations work?
      </Typography>

      <Typography>
        Customers can make reservations through participating venues on the
        Egora platform. For deposits paid for reservations, cancellations must
        be made at least 24 hours in advance to qualify for a full refund.
      </Typography>

      <Typography variant="h6" mt={2}>
        8. What happens if I need to cancel my reservation?
      </Typography>
      <Typography>
        If you need to cancel your reservation, please do so at least 24 hours
        in advance to receive a full refund of any deposit paid. Cancellations
        made within 24 hours or no-shows may not qualify for a refund.
      </Typography>

      <Typography variant="h6" mt={2}>
        9. How do I contact support for issues related to my order?
      </Typography>
      <Typography>
        For any order-related issues, please contact the venue where you placed
        your order directly. Egora facilitates communication, but refunds and
        order resolutions are the responsibility of the individual venues.
      </Typography>

      <Typography variant="h6" mt={2}>
        10. Is Egora responsible for refunds?
      </Typography>
      <Typography>
        No, Egora is not responsible for issuing refunds. Each venue is the end
        provider and manages its own refund policies and procedures.
      </Typography>

      <Typography variant="h6" mt={2}>
        11. How can I find participating venues using Egora?
      </Typography>
      <Typography mb={2}>
        You can find participating venues on our website or through the Egora
        app, where you can view their services, menus, and make reservations or
        place orders.
      </Typography>
    </Box>
  );
};

export default FaqModalContent;
