import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";

const PrivacyModalContent = () => {
  return (
    <Box>
      <Typography variant="h6" mt={2}>
        How We Handle Your Data
      </Typography>

      <Typography>
        Egora (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) is dedicated
        to safeguarding the privacy and security of your personal information.
        This Privacy Policy outlines the methods through which we collect, use,
        disclose, and protect the information gathered via our SaaS e-commerce
        ordering platform (the &quot;Platform&quot;). By accessing or utilising
        our Platform, you agree to the collection, use, and disclosure of your
        personal information as described in this Privacy Policy. Should you
        disagree with any part of this Privacy Policy, we advise you to refrain
        from using our Platform.
      </Typography>

      <Typography variant="h5" mt={2}>
        Information We Collect
      </Typography>

      <Typography variant="h6" mt={2}>
        Personal Information
      </Typography>
      <Typography>
        We may collect personal details such as your name, email address, phone
        number, and billing address when you create an account or place an order
        through our Platform.
      </Typography>

      <Typography variant="h6" mt={2}>
        Order Information
      </Typography>

      <Typography>
        We collect details related to the orders placed via our Platform,
        including product or service information, transaction details, payment
        data, and shipping address.
      </Typography>

      <Typography variant="h6" mt={2}>
        Usage Data
      </Typography>
      <Typography>
        We may automatically collect certain information when you interact with
        our Platform, including your IP address, browser type, device
        information, access times, and pages viewed.
      </Typography>

      <Typography variant="h6" mt={2}>
        Cookies and Similar Technologies
      </Typography>

      <Typography>
        We may employ cookies, web beacons, and similar technologies to gather
        information regarding your interactions with our Platform, such as your
        preferences and browsing history. You can manage your cookie settings
        via your browser options.
      </Typography>

      <Typography variant="h5" mt={2}>
        How We Use Your Information
      </Typography>

      <Typography variant="h6" mt={2}>
        Provision and Enhancement of Services
      </Typography>
      <Typography>
        We utilise the information collected to deliver our services, process
        orders, and improve the functionality and user experience of our
        Platform.
      </Typography>

      <Typography variant="h6" mt={2}>
        Communication
      </Typography>

      <Typography>
        Your information may be used to communicate with you regarding your
        orders, provide customer support, and send updates, newsletters, or
        promotional content related to our services. You may opt-out of
        receiving promotional communications by following the unsubscribe
        instructions provided in the email.
      </Typography>

      <Typography variant="h6" mt={2}>
        Analytics and Personalization
      </Typography>
      <Typography>
        We may analyse the data to track trends, monitor Platform usage, and
        personalise your experience through tailored content and advertisements.
      </Typography>

      <Typography variant="h6" mt={2}>
        Legal Compliance
      </Typography>
      <Typography>
        Your information may be used and disclosed to comply with relevant laws,
        regulations, legal processes, or enforceable governmental requests, and
        to safeguard our rights, privacy, safety, or property, as well as those
        of our users and the public.
      </Typography>

      <Typography variant="h5" mt={2}>
        Information Sharing
      </Typography>

      <Typography variant="h6" mt={2}>
        Third-Party Service Providers
      </Typography>
      <Typography>
        We may share your information with third-party service providers who
        assist us in operating our Platform, such as payment processors,
        shipping partners, and email service providers. These service providers
        are authorised to use your information solely to perform services for
        us.
      </Typography>

      <Typography variant="h6" mt={2}>
        Business Transactions
      </Typography>
      <Typography>
        In the event of a merger, acquisition, or sale of all or a portion of
        our assets, your information may be transferred as part of such a
        transaction. You will be notified via email and/or a prominent notice on
        our Platform of any changes regarding the ownership or usage of your
        personal information.
      </Typography>

      <Typography variant="h6" mt={2}>
        Legal Obligations
      </Typography>
      <Typography>
        We may disclose your information to comply with applicable laws,
        regulations, legal processes, or enforceable governmental requests.
      </Typography>

      <Typography variant="h5" mt={2}>
        Data Security
      </Typography>

      <Typography>
        We take reasonable precautions to protect your personal information from
        unauthorized access, use, or disclosure. However, please be aware that
        no method of transmission over the Internet or electronic storage is
        completely secure. Although we endeavour to protect your personal
        information, absolute security cannot be guaranteed.
      </Typography>

      <Typography variant="h6" mt={2}>
        Your Choices
      </Typography>

      <Typography>
        You have the right to access, amend, or delete your personal information
        that we hold. You may also opt to restrict or object to the processing
        of your personal information. For any requests or inquiries related to
        your personal data, please contact us at info@egora.pk.
      </Typography>

      <Typography variant="h6" mt={2}>
        Children’s Privacy
      </Typography>
      <Typography>
        Our Platform is not intended for individuals under the age of 18. We do
        not knowingly collect personal information from children. Should we
        become aware that personal information has been inadvertently collected
        from a child, we will take prompt measures to delete such data.
      </Typography>

      <Typography variant="h6" mt={2}>
        Updates to This Privacy Policy
      </Typography>
      <Typography>
        We may revise this Privacy Policy periodically to reflect changes in our
        practices or legal requirements. Any significant modifications will be
        communicated by posting an updated version of the Privacy Policy on our
        Platform and updating the &quot;Effective Date&quot; at the top of this
        page. We encourage you to periodically review this Privacy Policy to
        stay informed of any changes.
      </Typography>

      <Typography variant="h6" mt={2}>
        Contact Us
      </Typography>

      <Typography mb={2}>
        If you have any questions or concerns regarding this Privacy Policy or
        our privacy practices, please contact us at info@egora.pk or call us at
        +92 302 5550211.
      </Typography>
    </Box>
  );
};

export default PrivacyModalContent;
