import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import { fontWeight } from "@mui/system";

const RefundModalContent = () => {
  return (
    <Box>
      <Typography mt={2}>
        Egora provides a platform for hospitality venues to manage orders,
        reservations, and delivery/pick-up services. This policy outlines the
        general guidelines for refunds; however, each venue is the end provider
        responsible for fulfilling customer orders. Egora acts as a service
        provider and is not directly responsible for refunds, which are handled
        by the respective venue.
      </Typography>

      <Typography variant="h5" mt={2}>
        1. General Refund Eligibility
      </Typography>

      <Typography mt={2}>
        Refunds may be granted under the following conditions:
      </Typography>
      <Box component="ul" sx={{ marginLeft: 4, marginTop: 2 }}>
        <li>
          <span style={{ fontWeight: "bold", fontSize: "16px" }}>
            Incorrect or Defective Orders:{" "}
          </span>
          If the order received is incorrect, incomplete, or defective (e.g.,
          wrong items, spoiled food, or damaged packaging), you may be eligible
          for a refund through the venue.
        </li>
        <li>
          <span style={{ fontWeight: "bold", fontSize: "16px" }}>
            {" "}
            Order Cancellations:
          </span>{" "}
          Cancellations made within the allowed time frame (specified below) are
          eligible for refunds.
        </li>
        <li>
          <span style={{ fontWeight: "bold", fontSize: "16px" }}>
            Non-Delivery or Late Delivery:
          </span>
          For delivery or pick-up orders, refunds may be issued if there is a
          significant delay, and no communication has been made by the venue.
        </li>
      </Box>

      <Typography variant="h5" mt={2}>
        2. QR Code Orders (On-Site at the Venue)
      </Typography>

      <Typography variant="h6" mt={2}>
        Refund Process
      </Typography>

      <Typography>
        <Box component="ul" sx={{ marginLeft: 4, marginTop: 2 }}>
          <li style={{ marginLeft: 4 }}>
            <Typography variant="h6">
              For Incorrect or Defective Orders:
            </Typography>
            <Typography ml={4}>
              ○ Since QR code orders are placed on-site at the venue, any issues
              must be reported to the venue staff immediately after receiving
              the order.
              <br /> ○ The venue is responsible for handling refunds,
              replacements, or corrections of the order based on their policy.
            </Typography>
          </li>

          <li style={{ marginLeft: 4 }}>
            <Typography variant="h6">For Cancellations:</Typography>
            <Typography ml={4}>
              ○ QR code orders are processed directly by the venue and cannot
              typically be cancelled after being placed. Refunds or
              cancellations will only be granted at the venue’s discretion if
              the preparation of the order has not yet begun.
            </Typography>
          </li>
        </Box>
      </Typography>

      <Typography variant="h5" mt={2}>
        3. Delivery and Pick-Up Orders
      </Typography>

      <Typography variant="h6" mt={2}>
        Refund Process
      </Typography>

      <Typography>
        <Box component="ul" sx={{ marginLeft: 4, marginTop: 2 }}>
          <li style={{ marginLeft: 4 }}>
            <Typography variant="h6">For Late Delivery/Pick-Up:</Typography>
            <Typography ml={4}>
              ○ If your delivery or pick-up order is delayed by more than 30
              minutes beyond the promised time and there has been no
              communication from the venue, you may contact the venue directly
              to request a refund.
            </Typography>
          </li>

          <li style={{ marginLeft: 4 }}>
            <Typography variant="h6">
              For Incorrect or Defective Orders:
            </Typography>
            <Typography ml={4}>
              ○ Issues with delivery or pick-up orders should be reported to the
              venue within 24 hours of receiving the order. Refunds will be
              handled by the venue after verification of the issue.
            </Typography>
          </li>

          <li style={{ marginLeft: 4 }}>
            <Typography variant="h6">Cancellations:</Typography>
            <Typography ml={4}>
              ○ Delivery or pick-up orders can be cancelled within 5 minutes
              after placing the order or provided that the venue has not started
              preparing the items. If preparation has started, cancellations and
              refunds are not guaranteed and depend on the venue&#39;s policy.
            </Typography>
          </li>
        </Box>
      </Typography>

      <Typography variant="h5" mt={2}>
        4. Table Reservations
      </Typography>

      <Typography variant="h6" mt={2}>
        Refund Process
      </Typography>

      <Typography>
        <Box component="ul" sx={{ marginLeft: 4, marginTop: 2 }}>
          <li style={{ marginLeft: 4 }}>
            <Typography variant="h6">Reservation Deposits:</Typography>
            <Typography ml={4}>
              ○ If you’ve made a deposit for a reservation, cancellations must
              be made at least 24 hours in advance to qualify for a full refund.
              <br />○ Cancellations within 24 hours or no-shows may result in a
              partial refund or no refund, based on the venue&#39;s policy.
            </Typography>
          </li>

          <li style={{ marginLeft: 4 }}>
            <Typography variant="h6">Venue Cancellations:</Typography>
            <Typography ml={4}>
              ○ If a venue cancels a confirmed reservation, you are entitled to
              a full refund of any deposit paid.
            </Typography>
          </li>
        </Box>
      </Typography>

      <Typography variant="h5" mt={2}>
        5. How to Request a Refund
      </Typography>

      <Typography>
        For any refund requests, please contact the venue where the order was
        placed. Each venue has its own refund policy, and they are responsible
        for addressing and resolving any issues with orders, cancellations, or
        refunds.
      </Typography>

      <Typography mt={2}>
        Egora, as a service provider to the venue, cannot issue refunds directly
        but supports both customers and venues in facilitating communication and
        resolution.
      </Typography>

      <Typography variant="h5" mt={2}>
        6. Exclusions
      </Typography>

      <Typography mt={2}>
        <Box component="ul" sx={{ marginLeft: 4, marginTop: 2 }}>
          <li>
            Refunds are not available for orders cancelled outside the allowed
            window or for personal preferences such as taste unless the item was
            incorrect or defective.
          </li>
         
          <li>
            Egora is not responsible for managing refund requests for orders
            that were placed and fulfilled by the venues.
          </li>
        </Box>
      </Typography>

      <Typography mt={4} mb={2}>
        For any additional questions or concerns about your order, please
        contact the venue directly or reach out to our customer support team at
        info@egora.pk or call us at +92 302 5550211.
      </Typography>
    </Box>
  );
};

export default RefundModalContent;
