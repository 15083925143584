import { Box, Divider, IconButton } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import { DialogTitle, DialogContent } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import RefundModalContent from "./RefundModalContent";
import RefundModalHeader from "./RefundModalHeader";

const RefundModal = ({ width, open, item, onClose }) => {
  return (
    <>
      <ConfirmDialog width={width} open={open} onClose={onClose}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <RefundModalHeader item={item} />
            <IconButton aria-label="close" onClick={() => onClose(!open)}>
              <Iconify
                sx={{
                  height: 25,
                  width: 25,
                }}
                icon="mdi:close"
                color="##222"
              />
            </IconButton>
          </Box>
        </DialogTitle>
        <Box>
          <Divider />
        </Box>
        <DialogContent sx={{ typography: "body2" }}>
          <RefundModalContent />
        </DialogContent>
      </ConfirmDialog>
    </>
  );
};
export default RefundModal;
