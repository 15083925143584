import { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// @mui
import {
  Box,
  Grid,
  Link,
  Stack,
  Divider,
  Container,
  Typography,
  IconButton,
} from "@mui/material";
import { _socials } from "../../_mock/arrays";
import Logo from "../../components/logo";
import Iconify from "../../components/iconify";
import PolicyModal from "./policymodal/PolicyModal";
import PrivacyModal from "./privacyModal/PrivacyModal";
import FaqModal from "./faqModal/FaqModal";
import RefundModal from "./refundModal/RefundModal";

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: "Legal",
    children: [
      { name: "Terms & Conditions", href: "#" },
      { name: "Privacy Policy", href: "#" },
      { name: "Refund Policy", href: "#" },
      { name: "FAQ", href: "#" },
    ],
  },
  {
    headline: "Contact",
    children: [
      { name: "info@egora.pk", href: "#" },
      { name: "140 CCA, Sector C, DHA Phase 5 Lahore", href: "#" },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const { pathname } = useLocation();
  const [openPolicyModal, setOpenPolicyModal] = useState(false);

  const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] = useState(false);

  const [openRefundPolicyModal, setOpenRefundPolicyModal] = useState(false);

  const [openFaqModal, setOpenFaqModal] = useState(false);

  const handlePolicyModalOpen = () => {
    setOpenPolicyModal(true);
  };

  const handleClose = () => {
    setOpenPolicyModal(false);
  };

  const handlePrivacyPolicyModalOpen = () => {
    setOpenPrivacyPolicyModal(true);
  };

  const handleClosePrivacyModal = () => {
    setOpenPrivacyPolicyModal(false);
  };

  const handleRefundPolicyModalOpen = () => {
    setOpenRefundPolicyModal(true);
  };

  const handleCloseRefundModal = () => {
    setOpenRefundPolicyModal(false);
  };

  const handleFaqModalOpen = () => {
    setOpenFaqModal(true);
  };

  const handleCloseFaqModal = () => {
    setOpenFaqModal(false);
  };

  const isHome = pathname === "/";

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: "relative",
        bgcolor: "background.default",
      }}
    >
      <Divider />

      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{
            xs: "center",
            md: "space-between",
          }}
          sx={{
            textAlign: {
              xs: "center",
              md: "left",
            },
          }}
        >
          <Grid item xs={12} sx={{ mb: 0 }}>
            <Logo sx={{ mx: { xs: "auto", md: "inherit" } }} />
          </Grid>

          <Grid item xs={8} md={4}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              Hospitality Meets Innovation
            </Typography>

            <Stack
              spacing={1}
              direction="row"
              justifyContent={{ xs: "center", md: "flex-start" }}
              sx={{
                mt: 5,
                mb: { xs: 5, md: 0 },
              }}
            >
              {_socials.map((social) => (
                <a target="_blank" href={social.path}>
                  <IconButton key={social.name}>
                    <Iconify icon={social.icon} color="primary.main" />
                  </IconButton>
                </a>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack
              spacing={5}
              justifyContent="space-between"
              direction={{ xs: "column", md: "row" }}
            >
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: "center", md: "flex-start" }}
                >
                  <Typography
                    component="div"
                    variant="overline"
                    color="primary.main"
                  >
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      to={link.href}
                      color="inherit"
                      variant="body2"
                      onClick={
                        link.name === "Terms & Conditions"
                          ? handlePolicyModalOpen
                          : link.name === "Privacy Policy"
                          ? handlePrivacyPolicyModalOpen
                          : link.name === "FAQ"
                          ? handleFaqModalOpen
                          : link.name === "Refund Policy"
                          ? handleRefundPolicyModalOpen
                          : ""
                      }
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          variant="caption"
          component="div"
          sx={{
            mt: 5,
            pb: 5,
            textAlign: { xs: "center", md: "left" },
          }}
        >
          © 2024. All rights reserved
        </Typography>
      </Container>
      {openPolicyModal && (
        <PolicyModal width="md" open={openPolicyModal} onClose={handleClose} />
      )}

      {openPrivacyPolicyModal && (
        <PrivacyModal
          width="md"
          open={openPrivacyPolicyModal}
          onClose={handleClosePrivacyModal}
        />
      )}

      {openRefundPolicyModal && (
        <RefundModal
          width="md"
          open={openRefundPolicyModal}
          onClose={handleCloseRefundModal}
        />
      )}

      {openFaqModal && (
        <FaqModal
          width="md"
          open={openFaqModal}
          onClose={handleCloseFaqModal}
        />
      )}
    </Box>
  );

  return mainFooter;
}
