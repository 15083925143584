import { Box, Divider, IconButton } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import { DialogTitle, DialogContent } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import PrivacyModalContent from "./PrivacyModalContent";
import PrivacyModalHeader from "./PrivacyModalHeader";


const PrivacyModal = ({ width, open, item, onClose }) => {
  return (
    <>
      <ConfirmDialog width={width} open={open} onClose={onClose}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <PrivacyModalHeader item={item} />
            <IconButton aria-label="close" onClick={() => onClose(!open)}>
              <Iconify
                sx={{
                  height: 25,
                  width: 25,
                }}
                icon="mdi:close"
                color="##222"
              />
            </IconButton>
          </Box>
        </DialogTitle>
        <Box>
          <Divider />
        </Box>
        <DialogContent sx={{ typography: "body2" }}>
          <PrivacyModalContent />
        </DialogContent>
      </ConfirmDialog>
    </>
  );
};
export default PrivacyModal;
